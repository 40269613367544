export default {
    crypto: {
        title: {
            0: "Krypto satın alın",
            1: "Krypto satın alın",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Alın.",
            2: "Satın",
            3: "Reklamcılar (Tamamlama hızı)",
            4: "Tradi",
            5: "Ödül",
            6: "Mevcut",
            7: "Sınır",
            8: "Ödeme Yöntemi",
            9: "Satış",
            10: "Reklamcısı reklamın transaksyon sınırlarını yerleştirebilir ve girdikten sonra sınırlama sebebini göreceksiniz.",
        },
        buy: {
            0: "Krypto satın alın",
            1: "Şifreleri satın",
            2: "Tarihi",
            3: "Destek",
            4: "Ödeme",
            5: "Alın",
            6: "Ödeme yöntemini seç",
            7: "Est. Gelme zamanı",
            8: "FEE",
            9: "Sıra Ayrıntıları",
            10: "Ödeme yöntemi",
            11: "Daha fazla öğrenin",
            12: "Ödül",
            13: "Toplam (ücret dahil)",
            14: "Kaçırıcı",
            15: "HKDFinanc'dan ayrılıyorsunuz ve üçüncü parti olarak kredi/debet kartı ödeme hizmeti sağlayan lisanslı finans kuruluşu Mercuryo'ya yeniden yönlendirileceksiniz. Servis kullanımında oluşan her riski Mercuryo tarafından yüklenecek. Lütfen devam etmeden önce kullanma şartları okuyun ve kabul edin.",
            16: "Kullanma Vakitlerini okudum ve kabul ettim.",
            17: "Sen alacaksın.",
            18: "Teslim et",
            19: "Lütfen miktarı girin",
            20: "Yetersiz hesap dengesi",
            21: "Mevcut",
            22: "Nota Hesabı Mevcut",
            23: "Tek satın sınırı",
            24: "Minimum",
            25: "Maksimum",
        }
    },
    common: {
        view: "Görüntü",
        confirm: "Teyit et",
        cancel: "Lütfen",
        deposit: "Depozit",
        withdraw: "Çıkart",
        noData: "Şu anda hiçbir veri ulaşılabilir",
        submit: "Teslim et",
        modify: "Değiştir",
        hand: "Eller",
        confirm_verify: "İmzalamayı Teyit Et",
        next: "Sonraki adım",
        complete: "Bitti",
        delete: "Sil",
		countryCode: "Ülke kodu",
    },
    addnew: {
        realFirst: "Lütfen ilk kimlik kimliğini gerçekleştirin",
        payFirst: "Lütfen önce transaksyon parolanı ayarlayın",
        password1: "Giriş parolanı değiştir",
        password2: "Çıkarma parolanı değiştir",
        paypassTit1: "Lütfen çekilme parolanızı ayarlayın",
        oldPass: "Eski parola",
        allView: "Hepsi okudu",
        payPass: "Transaksyon paroli",
        buyUp: "Alın.",
        buyDown: "Aşağı al",
        confirmOrder: "Emri doğrula",
        name: "İsim",
        fee: "Fee",
        orderCycle: "Zaman",
        loan: "Loan",
        loanTit: "Yardım kredileri",
        loanHostory: "Kredi kayıtları",
        labelProductTitle: "Üretim İsmi",
        immediateRepayment: "Hemen ödeme",
        loadAmount: "Beklenmiş kredi miktarı",
        RepaymentCycle: "Kredi ödeme döngüsü",
        day: "Gün",
        dailyRate: "Günlük derece",
        repaymentMethod: "Tekrarlama metodu",
        lendingCompany: "Kredi şirketi",
        loanTip: "Kredi kredi (lütfen resim temiz ve görünür olduğundan emin olun)",
        loanTip1: "Özellik sertifikasını yükle",
        loanTip2: "Geldi kanıtı (işçilik ilişkisi)",
        loanTip3: "Banka ifade detayları",
        loanTip4: "Kimlik kartınızın önündeki fotoğrafını yükleyin",
        interest: "İlginç",
        repaid: "Tekrarlama",
        unpaid: "Ödemez",
        tab1: "Şifreler",
        tab2: "Kontrakt",
        tab3: "Seçenek",
        available: "Mevcut sınır",
        pending: "Kilit",
        equivalent: "Aynı",
        fundTransfer: "Fond aktarımı",
        flashExchange: "Flash exchange",
        select_breed: "Parayı Seç",
        from: "Senden",
        to: "- Evet.",
        transferQuantity: "Taşıma miktarı",
        repaymentMethod1:"Doğuştan bir ödül."
    },
    params: {
        product_foreign: "Forex",
        product_shop: "İşler",
        product_number: "Hindiler",
        product_stock: "Paylar",
        product_coin: "Şifreler",
        billTypes: [
            {v: 101, name: 'Tekrar yükle'},
            {v: 102, name: 'Depozit'},
            {v: 103, name: 'Dock this window'},
            {v: 104, name: 'Tamam.'},
            {v: 105, name: 'Depozit'},
            {v: 106, name: 'Depozit'},
            {v: 201, name: 'Dondurma kaldır'},
            {v: 202, name: 'Çıkarma'},
            {v: 203, name: 'Kaçırma başarılı.'},
            {v: 204, name: 'Çıkarma başarısız oldu'},
            {v: 205, name: 'Gönderme ücreti'},
            {v: 206, name: 'Transfer dışarı'},
            {v: 207, name: 'İçeri aktar'},
            {v: 208, name: 'Coin transfer out'},
            {v: 209, name: 'Coin transfer in'},
            {v: 301, name: 'Kontrakt yönetme ücreti'},
            {v: 302, name: 'Anlaşma gelirleri'},
            {v: 303, name: 'Anlaşma kaybı'},
            {v: 304, name: 'Kontrakt sınırı'},
            {v: 305, name: 'Anlaşma depozit ödemesi'},
            {v: 311, name: 'Alış Seçenekleri'},
            {v: 312, name: 'Seçenek geri dönüyor'},
            {v: 313, name: 'Seçenek Tekrar'},
            {v: 314, name: 'İhtiyariyat Yönetim Beslemesi'},
            {v: 315, name: 'Para satın dondurması'},
            {v: 316, name: 'Para satın değerlendirmesi'},
            {v: 317, name: 'Coin Purchase Return'},
            {v: 318, name: 'Kabul edilen Coins alınması'},
            {v: 319, name: 'Kabul edilen Coins alınması'},
            {v: 320, name: 'Para satışları dondurulmuş.'},
            {v: 321, name: 'Para satış deducasyonu'},
            {v: 322, name: 'Coin satış dönüşü'},
            {v: 323, name: 'Coins satılmış hesaba'},
            {v: 324, name: 'Coins satılmış hesaba'},
            {v: 325, name: 'Para yönetimi ücreti'},
            {v: 401, name: 'Mini makinesi ekleme'},
            {v: 402, name: 'Mini makinesi geri dönüş'},
            {v: 403, name: 'Mini makinelerin geliri'},
            {v: 404, name: 'Mini makinesi çıktı'},
            {v: 405, name: 'Mini makinesi kullanma ücreti çıktı'},
            {v: 411, name: 'Ödünç almak'},
            {v: 412, name: 'Kapatma maliyeti'},
            {v: 413, name: 'Başarılı ödeme'},
        ],
    },
    footer: {
        nav1: "Home",
        nav2: "Satış",
        nav3: "Konumlar",
        nav4: "Hub",
        kline_set1: "Pazar ayarlaması",
        kline_set2: "Aktarma Ayarları",
    },
    login: {
        login: "İçeri gir",
        register: "Kayıt",
        type1: "Telefon",
        type2: "E-posta",
        login_btn: "İçeri gir",
        register_btn: "Şimdi kaydedin",
        psd_login: "Parola giriş",
        verify_login: "Kontrol kodu giriş",
        forget: "Parola unut?",
        no_account: "Hesap yok?",
        have_account: "Mevcut hesap",
    },
    home: {
        home: {
            nav1: "Günlük Ranking",
            nav2: "Temel",
            nav3: "Bizim hakkımızda.",
            nav4: "Referral",
            most_follow: "Popüler",
            news_type1: "Strateri",
            news_type2: "News",
            news_type3: "Takvim",
            news_tab1: "Hepsi",
            news_tab2: "Forex",
            news_tab3: "Paylar",
            news_tab4: "Üyelik",
            news_tab5: "Indices",
            news_tab6: "Veri",
            news_tab7: "Olay",
            news_tab8: "Vakti",
            label_previous: "Önceki",
            label_predictive: "Önceden",
            label_announced: "Anlaşıldı ",
        },
        kilne: {
            label_bp: "Kapat",
            label_sp: "Aç",
            label_height: "Yükseklik",
            label_low: "Düşük",
            tip: "Merhaba, ben HKDFINANCGPT çeşitli asistanım.",
            row1_tit: "Strateji",
            row1_rinei: "İçindeki",
            row1_short: "Kısa Term",
            row1_middle: "Orta Term",
            row1_time: "Serbest zamanı",
            row1_tit2: "Trading Strategy",
            data_sources: "Veri kaynakları",
            row2_tit1: "Veri",
            row2_tit2: "Bilgi",
            row2_label_sell: "Satıcı",
            row2_label_buy: "Alıcı",
            tip2: "Sadece referans için, şirketin pozisyonu olarak değil.",
            tip3: "Her 15 dakika güncelleştir",
            row2_tit3: "Ödül değişiklikleri",
            minute: "Minute",
            today: "Bugün",
            right_tit7: "Ödül değişiklikleri menzili",
            now_price: "Şimdiki fiyat",
            right_low_price: "Düşük",
            right_height_price: "Yükseklik",
            details: "Ayrıntılar",
            right_label1: "Tek işlemler sayısı",
            right_label2: "Maksimum leverage",
            right_label3: "Kontrakt Ölçüsi",
            right_label4: "En yüksek toplam pozisyon miktarı",
            right_label5: "Güzel fiyat farkı",
            right_label6: "Gece ücreti",
            hand: "Eller",
            right_sell: "Satın",
            right_buy: "Alın.",
            right_collection_time: "Kolleksyon zamanı",
            right_tip3: "Tüm transaksyon boyutundan topla",
            right_label9: "Satış oturumu",
            right_label10: "Şimdiki dönem",
            right_label11: "Sonraki Period",
        },
        placeorder: {
            direction_buyPrice: "Satın hızı",
            direction_sellPrice: "Satış hızı",
            direction_sell: "Satın",
            direction_buy: "Alın.",
            market_price: "Pazar düzeni",
            check_price: "Kayıt",
            sell_tip: "Fiyat koşullarına ulaştığında satın",
            buy_tip: "Fiyat şartlarla karşılaştığında satın alın.",
            trade_number: "Transaksyon miktarı",
            hand: "Eller",
            select_lever: "Seçim leveri",
            right_tip: "Görev gerekli",
            balance: "Mevcut",
            label_stop_win_price: "Geri kalanı durdur",
            label_stop_lose_price: "Kaybı durdur.",
            expected_profit_loss: "Gelecek kazanç ve kaybı",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Daha yüksek ve aşağı bir gün",
            p: "Sıcak noktaları yakalayın ve fırsatları yakalayın.",
            renew: "Güncelleştirildi",
            growth_chart: "Büyüme grafiği",
            drop_list: "Bırak Listesi",
            show_closed_market: "Pazar Marklarını Göstermek",
            filter1: "Tamam.",
            filter2: "Dışişleri ",
            filter3: "Üyelik",
            filter4: "Sertifikatı paylaş",
            filter5: "Indeks",
            filter6: "Şifreler",
            td1: "Ranking",
            td2: "Trend",
            td3: "Günlük artış/satış fiyatı",
        },
        remind: {
            now_sell_price: "Şimdiki satış fiyatı",
            direction_buyPrice: "Satın hızı",
            direction_sellPrice: "Satış hızı",
            direction_sell: "Satın",
            direction_buy: "Alın.",
            equal: "Aynı",
            p: "Bu fiyatların ulaştığı zaman bana hatırlat.",
            btn1: "Hatırlatma",
            list_tit: "Hatırlatma Listesi",
        },
    },
    my: {
        report: {
            tit: "Hesap Raporu",
            tab1: "Rapor",
            tab2: "Günlük ifade",
            tab3: "Aylık ifade",
            history_tit: "Transaksyon Tarihi Raporu",
            account_tit: "Hesap Raporu",
            filter: "Ekran",
            tip1: "Lütfen transaksyon tarihi raporunuzu dışarı aktarmak için uygun tarih menzilini seçin.",
            tip2: "Özel zaman",
            email_send: "E-postaya gönder",
        },
        accountMsg: {
            tit: "Hesap bilgisi",
            label1: "Kişisel Bilgi",
        },
        bill: {
            tit: "Başkan akış detayları",
            all: "Tamam.",
            time: "Zaman",
        },
        feedback: {
            tit: "Funksiyonel tavsiyeler",
        },
        identity: {
            tit: "Tam Profil",
            type1: "Ulusal Kimlik",
            type2: "Sürücü Lisans",
            type3: "Pasaportu",
            tip: "Verilen tüm bilgiler ve belgeler tamamlandığını onayladım.",
        },
        my: {
            is: "Dur",
            no: "Denetilmemiş",
            verify: "Verilen",
            id_verify: "Kimlik Doğrulaması",
            verify_tip: "Yerleştirmek ve ticaret başlatmak için tamam kimlik doğrulaması",
            no_verify: "Şimdi doğrula",
            balance: "Mevcut",
            profit_loss: "Profit ve kaybı",
            marginLevel: "Margin Seviye",
            btn1: "Gerçek bir hesaba değiştir",
            deposit_withdraw_record: "Kayıtları gönderin ve çekin",
            capital_flow_details: "Başkan akış detayları",
            welfare_center: "Welfare Center",
            tip1: "Öyle mi",
            tip2: "Kabul edilecek kalem ödülü",
            feedback: "Funksiyonel tavsiyeler",
            report: "Hesap Raporu",
            setting: "Ayarlar",
        },
        news: {
            tit: "Bildirin",
        },
        record: {
            tit: "Kayıtları gönderin ve çekin",
            cycle: "Döngü",
        },
        share: {
            tit: "Arkadaşları davet et",
            invite_success: "Arkadaşları başarıyla davet etti",
            tip1: "Para ödülleri",
            tip2: "Arkadaşları en yüksek tarafı almaları davet et",
            tip3: "Arkadaşları para almak için davet et.",
        },
        welfare: {
            tit: "Welfare Center",
            tab1: "Kart listesi",
            tab2: "Etkinlik",
            history_record: "Tarihi",
            period_validity: "Keçerlik periyodu",
        },
        wallet: {
            tit: "CüzdanComment",
            addNew: "Yeni cüzdanı ekle",
        },
        withdraw: {
            tit: "Cash out",
            addNew: "Yeni cüzdanı ekle",
        },
    },
    order: {
        demo_account: "Simüle edilmiş hesap",
        real_account: "Canlı Hesap",
        tip: "Açtıktan sonra altın transaksyonlar için depolayabilir.",
        tab1: "Konum",
        tab2: "Kayıt",
        tab3: "Tarihi",
        list_empty: "Şu anda beklenen emirler yok.",
        start_trade: "Satış başlat",
        hand_tip: "Girdi menzili",
        kline: {
            direction_sell: "Satın",
            direction_buy: "Alın.",
            open_price: "Açık fiyat",
            now_price: "Şimdiki fiyat",
            trade_account: "Satış hesabı",
            demo_account: "Simüle edilmiş hesap",
            order_id: "Sıra Kimliği",
            open_time: "Aç Zamanı",
            stop_profit: "Geri kalanı durdur",
            stop_loss: "Kaybı durdur.",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "Yüzülen P/L",
            overnight_fee: "Gece ücreti",
            margin: "Margin",
            maintainsMargin: "Yedekleme Boğazı",
            open_value: "Değeri açılıyor",
            now_value: "Ağımdaki değer",
            notset: "Ayarlanmış",
            close_position: "Konum kapatılıyor",
            close_number: "Normal miktarı",
            onclick_close: "Bir tıklama kapatma pozisyonu",
            btn: "Kapatma pozisyonunu doğrula",
            balance: "Mevcut",
            profit_loss: "Profit ve kaybı",
            netValue: "Ağ değeri",
            marginLevel: "Margin Seviye",
        },
        modify: {
            tit: "Konum Düzenini Değiştir",
            expected_profit_loss: "Gelecek kazanç ve kaybı",
        },
    },
    setting: {
        security: {
            modify_password_tit: "Yeni bir parola ayarlayın",
            verify_email_tit: "E-postayı doğrula",
            verify_password_tit: "Parolayı doğrula",
            verify_password_tip: "Devam etmek için HKDFINANC giriş parolanızı girin",
            verify_phone_tit: "Telefon numarasını doğrula",
            tit: "Güvenlik",
            tip: "Hesapınızın güvenliğini korumak ve hizmet bildirimlerimizi kabul etmek için en azından bir tür yetenekliğini etkinleştirmek öneriliyor.",
            label1: "Giriş parolanı değiştir",
            label2: "İmzalayın",
            label3: "İki doğrulama",
            label4: "Hizmetler yönetimi",
            tip2: "Yeni cihaz giriş doğrulaması gerekiyor",
        },
        tradenews: {
            tit: "Transaksyon Kılavuzu",
            tab1: "Çabuk başla.",
            tab2: "Gelişmiş öğrenme",
            tab3: "Yardım et",
            tab4: "HKDFINANC hakkında",
            viewMore: "Daha fazla görüntüle",

            details: {
                tit: "Dışişleri değiştirmeye giriş",
                tip1: "Tahmin edilen öğrenme süresi",
                tip2: "Minute",
            },
            tab1Row: {
                tit: "HKDFINANC ile ticaret etmeyi çabuk öğrenmek için bir sınıf odası.",
                tit1: "HKDFINANC'da ticaret nasıl yapıldı?",
                tit2: "Gerçek bir hesap nasıl a çacağız？",
                tit3: "Satış için bir pozisyonu nasıl a çacağız？",
                tit4: "Kabul etmeyi ve kaybını durdurmayı nasıl ayarlayabilir？",
                tit5: "Bir pozisyonu nasıl kapatmak？",
                p1: "Bu konuyu satın alıp ya da satın alabilirsiniz.",
                p2: "Hedef ürünün fiyat değişikliklerine dayanan ticaret",
                p3: "Örneğin, altın fiyatının yükselmesini beklerseniz, ürünü satın alın. Gerçekten, altın fiyatı azaldığını tahmin ediyorsanız, ürünü satın. Gelecek beklenmeye ulaşırsa,",
                p4: "İçinden alışıyor.",
                p5: "Ayrıca, HKDFINANC ''leveraged trading' sunuyor. Aynı miktarla daha fazla hedefleri satmak için kullanabilirsiniz.",
                p6: "Tahmin etmek kazançlarını arttırabilir ve kaybını da arttırabilir.",
                p7: "Bu da 'margin trading' olarak bilinir. Bir pozisyon a çtığınızda, HKDFINANC hesabınız belli bir dengeyi",
                p8: "INITIAL MARGIN",
                p9: "Ürüntüleri, satış ve satış yöntemleri seçin",
                p10: "Transaksyon",
                p11: "Sıradan yerleştirmeyi tamamlamak için 'Satın/Satın' tıklayın",
                p12: "'Parayı durdurma' ya da 'kaybı durma' ayarlama sana yardım edebilir.",
                p13: "Zaman kazançları ve kaybı sınırlarını kilitler.",
                p14: "Başarılı açılma sayfasına tıklayabilirsiniz",
                p15: "Parayı durdur/kaybetmeyi durdur",
                p16: "Bunu ayarlayın, ya da zarar ve kaybı durdurmak için 'Değiştir' düğmesine tıklayabilirsiniz.",
                p17: "Ayarla ya da iptal et",
                p18: "Yapılmış durumlarda zarar ve kaybı görülebilirsiniz.",
            },
            tab3Row: {
                h1: "HKDFINANC'a hoş geldiniz",
                h2: "Yardım et",
                tit0: "Bütün sorunlar",
                tit1: "Simüle edilmiş hesap",
                tit2: "Bir hesap açılıyor",
                tit3: "Para içeri ve dışarı",
                tit4: "Platform Ayarları",
                tit5: "Transaksyon Operasyonları",
                tit6: "Ödemeler ve Charges",
                tit7: "Mali Güvenlik ve Kurallar",
                tit1Tit1: "HKDFINANC simülasyon hesapları verir mi?",
                tit1Cont1: "<p>HKDFINANC müşterilerin çalışması için simülasyon hesaplar sağlıyor, transaksyonlar için internet platformumuz ve mobil uygulamalarımızı kullanmanıza izin verir ve kendinizi platform operasyonları ile hiçbir risksiz tanıtırsınız.</p>",
                tit1Tit2: "Simüle bir hesap nasıl oluşturulacak?",
                tit1Cont2: "<p>Sadece e-posta adresinizi veya telefon numarasınızı doldurmak için burada tıklamanız gerekiyor, kişisel parolanızı kayıt tamamlamak için ve simülasyon bir hesabı elde etmeniz gerekiyor. </p>",
                tit1Tit3: "Simülasyon hesaplarının kullanımı?",
                tit1Cont3: "<p>Simüle edilmiş hesaplar genellikle transaksyon arayüzü, veri ve operasyonlar hakkında gerçek hesaplar ile aynı. Simülasyon hesabında 50000 dolarlık sanal fon var. Platformun farklı fonksiyonlarını tanıtmak amacıyla, hiçbir finansal riski olmadan simülasyon operasyonları üzerinden müşterileri tanıtmak için</p>",
                tit1Tit4: "Fonları simulasyon hesabına nasıl inşa etmek?",
                tit1Cont4: "<p>Simülasyon hesabına para inşa etmeniz gerekmiyor. Simülasyon hesabını başarıyla kaydettiğinizde, hesabda toplam 50000 USD veya AUD olacak</p><p>Simülasyon hesabındaki net değer 200 USD veya AUD'den az olduğunda, sistem hesap fonlarınızı otomatik olarak 50000 USD veya AUD'ye yenileyecek.</p>",
                tit1Tit5: "Simülasyon hesabın kullanım dönemi var mı",
                tit1Cont5: "<p>Simülasyon hesabının değerli dönemi 90 gündür. 90 günden sonra gerçek bir hesap a çmazsanız, simulasyon hesabı dondurulacak ve ticaret edilemez ve gerçek bir hesap açtığınızda bozulmayacaksınız. </ p><p>Eğer 90 gün içinde gerçek bir hesap a çarsanız, simulasyon hesabı uzun süre geçerli olacak. Gerçek bir hesap a çtıktan sonra bile simulasyon hesabında çalışmaya devam edebilirsiniz.</p>",
                tit2Tit1: "HKDFINANC'in kaç hesap tipi?",
                tit2Cont1: "<p>Biz sadece kişiler için standart ve profesyonel ticaret hesapları sağlıyoruz ve şirketi açma veya ortak hesapları desteklemeyiz.</p>",
                tit2Tit2: "Bir ticaret hesabı a çabilirim?",
                tit2Cont3: "<p>Bir hesap açtığınızda sistem otomatik olarak bölgesinize dayanarak çözüm parasınızı belirleyecek ve değiştirilemez.</p>",
                tit2Tit4: "Gerçek bir hesap nasıl a çacağız?",
                tit2Cont4: "<p>Lütfen gerçek bir hesap a çmak için aşağıdaki adımları takip edin. HKDFINANC kayıt sayfasını girin ve e-posta telefonu numarasını kaydetmek için istekleri takip edin, giriş parolanı ayarlayın ve doğrulama gönderdikten sonra otomatik olarak oluşturun</p><p></p><p></p>",
                tit3Tit1: "Nasıl depozit yapabilirim?",
                tit3Cont1: "<p>HKDFINANC HKDFINANC müşterilerin seçmelerinin farklı depozit metodların ı sunuyor, ama bunların altında sınırlı olmayabilir:</p><p>1.Visa/Mastercard - Genelde hemen alınır</p><p>2.Çevrimi banka ödemesi genelde 1 çalışma günü önünde yapılır</p><p>3.Elektronik cüzdanlar (Skrill, Momo, Zalo gibi. Touch'n GO, Boost, etc.) - genelde aniden alınır</p><p>4. ATM kartı - genelde anlık kabul</p><p>5.QR mobil online banking - genelde anlık kabul</p>",
                tit3Tit2: "Üçüncü parti adı altında bank a hesabı/kartı kullanabilir miyim para depozite veya çekmek için",
                tit3Cont2: "<p>HKDFINANC üçüncü parti depozitlerini kabul etmez. Lütfen kendi adınızın altında kişisel banka hesabınızı kullanın ya da paralarınızı çekmek için. Eğer başkasının adını ya da şirketin banka hesabı/kartını depozit için kullanırsanız, paralar geri verilecek.</p>",
                tit3Tit3: "HKDFINANC hesabından çıkarma için nasıl uygulanacak?",
                tit3Cont3: "<p>Web platformuna girdikten sonra 'Cash Out' üzerinde tıklayabilirsiniz, bank a hesabını ya da Visa/Mastercard seçin, para alanını doldurabilirsiniz ve sonra 'Submit' üzerinde basın. Bilgilerin doğru olduğunu doğruladıktan sonra, HKDFINANC uygulamasınızı 1- 2 çalışma gününde işleyecek.</p>",
                tit4Tit1: "HKDFINANC'in kaç kez alışveriş teklif ediyor?",
                tit4Cont1: "<p>HKDFINANC platformu tarafından sunduğu leverage oranı düzenleyici yetkililerin kurallarına göre ayarlandı ve farklı ürünler için maksimum leverage de değişir. Lütfen detaylar için ticaret platformuna girin.</p>",
                tit4Tit2: "Parolamı nasıl değiştireceğim?",
                tit4Cont2: "<p>Platforma girdikten sonra yeni parolanı yeniden ayarlamak için [Daha fazla] - [Güvenlik Ayarları] - T Parola değiştirmek] seçebilirsiniz</p>",
                tit4Tit3: "HKDFINANC ticaret platformuna nasıl gireceğiz?",
                tit4Cont3: "<p>Uygulama: Giriş sayfasına girmek için 'Benim' ve üst sol köşesinde 'Giriş Kayıtları' üzerine tıklayın. </ p>Web: HKDFINANC'ın resmi web sitesinin üst sağ köşesinde 'Girin' üzerine tıklayın.<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Plataforma hatırlatmalarının metodları nedir?",
                tit4Cont4: "<p>HKDFINANC e- posta, SMS ve platformu basmaları üzerinden uyarılar.</p>",
                tit5Tit1: "HKDFINANC'in ne fiyat farkısı?",
                tit5Cont1: "<p>Biz düşük fiyat farkına yüklüyoruz ve hiçbir komisyonu yüklemiyoruz. Fiyat farkı yüzüştür, ve gerçek fiyat farkı sattığınız farklığa bağlı olacak ve özel bilgiler gerçek zamanlı ticaret platformunda gösterilecek.</p>",
                tit5Tit2: "Bir pazar fiyatı listesini nasıl kurmak?",
                tit5Cont2: "<p>'Ticaret' üzerine tıklamak platformun ticaret için teklif edebileceği tüm ürünleri gösterecek. Aramak, ürün seçip ticaret penceresini a çmak için sağdaki girdi kutusunda çeşitli kodu ya da isim girebilirsiniz. Satış penceresinde, şu anda fiyatı ve gerekli sınırı görebilirsiniz. Açık konumların sayısını el olarak ayarlayabilirsiniz ve durdurun zararı ayarlayabilirsiniz ve risk kontrolü için kaybını durdurabilirsiniz.</p>",
                tit5Tit3: "Bir kayıt formu nasıl oluşturmak?",
                tit5Cont3: "<p>[ticaret] içindeki bir ürün seçmek ve [satış/BUY] üzerine tıklamak bir ticaret penceresini a çılacak</p>",
                tit5Tit4: "Bir pozisyonu nasıl kapatmak?",
                tit5Cont4: "<p>Bir pozisyonu kapatmak için 'Aç''ı 'Konum' platformunda tıklayın, sonra kapatmak istediğiniz pozisyonu seçin ve sağ tarafta 'Kapat' düğmesine tıklayın.</p>",
                tit5Tit5: "Bekleyen bir düzeni nasıl değiştirecek ya da silecek?",
                tit5Cont5: "<p>Emirleri değiştirmek veya silmek için ''Emirler'' bölümündeki görüntü platformuna tıklayın ve ilerlemede değiştirmek veya silmek istediğiniz emirleri seçin</p>",
                tit6Tit1: "Satış platformunu kullanmak için bir alışveriş ücreti olacak mı?",
                tit6Cont1: "<p>HKDFINANC tamamen bir komisyon özgür ticaret platformudur. Biz düşük fiyat farkına yüklüyoruz, ve sizin özel transaksyon durumunuz üzerinde bağlı, platformun, bir gece ilgisi gibi fazla fiyat alabilir.</p>",
                tit6Tit2: "Depozit için bir ücret var mı?",
                tit6Cont2: "<p>Müşterilere bağlı bir depozit ödemesini ödemeyeceğiz, ancak ödemeniz veya ortak bankanız müşterilere karşılayabilir. Sana bir ücret verdiklerini bilgili bankalarla sormanızı öneririz.</p>",
                tit6Tit3: "Geri çekilme ücreti var mı?",
                tit6Cont3: "<p>HKDFINANC müşterilere para çekmesiyle ilgili hiçbir ücret yüklemeyecek, eğer çektiğiniz miktar minimal şartının altında olmadığı veya aylık maksimal özgür çekmesi sayısını aşmadığı sürece. Daha detaylı bilgi için, lütfen çekilme politikamıza bakın.</p>",
                tit7Tit1: "HKDFINANC hesabımda depoladığım para diğer amaçlar için kullanılacak mı?",
                tit7Cont1: "<p>Hayır. Retail müşterilerin depozitleri düzenleme kurallarına uygun olduğunda güvenlik hesaplarına ayrı olarak yerleştirilir. Parayı müşteri hesaplarına depolamaya ya ya da müşteri hesaplarından ya da ödeme yaptığında, HKDFINANC müşteri ödemeleri hakkında düzenleme kurallarına gerçekten uyuyor.</p>",
                tit7Tit2: "Eğer HKDFINANC bankrota girerse ya da borçlu olursa, param da etkilenecek mi?",
                tit7Cont2: "<p>Retail müşterilerin depozitleri düzenleme kurallarına uygun olduğunda güvenlik hesaplarına ayrı olarak yerleştirilir. Eğer HKDFINANC bankrot yaparsa, lisansını geri çevirmiş veya çalışmaya devam edemeyecek olursa, müşteriler fonları hala karantiniz edilebilir ve düzenleme yasalarında müşteriler fonu düzenlemelerine uygulayabilir.</p>",
                tit7Tit3: "HKDFINANC yasal kurallarına uyuyor mu?",
                tit7Cont3: "<p>Bizim şirketimiz bilgili şirket kanunlarına, mali düzenleme kanunlarına ve kurallarına tamamen uyuyor ve bilgili düzenleme ajanları tarafından yetkili ve ciddi düzenlenen bir mali hizmet sunucusu. Bizim şirketimiz müşterilerin mali güvenliğine ciddi düzenleme ihtiyaçlarına uygun büyük ve yeterli koruma sağlıyor.</p>",
                tit7Tit4: "Müşterinin kişisel bilgileri güvenli mi?",
                tit7Cont4: "<p>HKDFINANC Kişisel Veri Koruma Kurulumlarının ayarlarına kesinlikle uyuyor ve müşterilerin kişisel verileri asla açılmayacak. Şirket, çalışanların kanunlar ve kurallar tarafından belirlenen güvenlik ve gizlilik standartlarına uymasını ve müşteriler verilerinin gizlilik ve kullanımı ayarlamalarına yüksek dikkat çekmesini sağlayacaktır. Ayrıntılar için, lütfen şirketimizin yasal açıklama belgelerine bakın.</p>",
            },
            tab4Row: {
                span: "Q doğrulama yöntemi",
                p1: "HKDFINANC, yetkili kurumlar tarafından yetkili ve düzenlenmiş bir finans teknoloji şirketi, yatırımcıları basit ve uygun bir ticaret deneyimini sağlamaya odaklanıyor.",
                p2: "Yenilikçi HKDFINANC çeşitli ticaret platformu, en iyi mobil ticaret platformu ve en yeni güvenlik firm as ı gibi ödüller kazandı.",
                p3: "HKDFINANC platformu üzerinden uluslararası finans pazarları ile yatırım ve ticaret edebilirsiniz, stoklar, indikler, eşyalar, yabancı değişiklikler gibi yüzlerce popüler çeşitlikleri kaplayabilirsiniz. Sadece kullanıcı dostu mobil ve web ticaret platformlarına sınırlı değil, HKDFINANC size yarışma maliyetleri, hızlı transaksyon işleme, müşteriler hizmeti ve zengin bilgi desteği sağlıyor. Yatırımları ve ticaret fırsatlarınızı zamanlı şekilde yakalamanıza yardım ediyor.",
                p4: "HKDFINANC endüstri yetkilileri ve birçok kurumlar tarafından ciddi düzenlenmiş.",
                p5: "HKDFINANC Holding, Cayman Island Monetary Authority (CIMA) tarafından 1612446 numaralı SIB lisansı numarası ile yetkili ve düzenlenmiş. Cayman lisansı tabağındaki daha detaylar için lütfen CIMA'nın resmi web sitesini www.cima.ky'de ziyaret edin.（",
                p6: "）Sorgulama yap. HKDFINANCGlobal Hong Kong Güvenlik ve Yatırım Komisyonu (ASIC) tarafından onaylanan bir Finans Hizmetleri Lisansı (AFSL 398528) sahiptir. Hong Kong fotoğrafları hakkında daha fazla detaylar için lütfen ASIC resmi web sitesini www.asic.gov.au'da ziyaret edin.（",
                p7: ") Sorgulama yap. HKDFINANC Uluslararası GB20025791 lisansı sayısıyla Mauritius Finanç Hizmetler Komisyonu (FSC) tarafından yetkili ve düzenlenmiş. Mauritius lisansı plakası hakkında daha fazla bilgi için, lütfen www.fscmauricius.org'daki FSC resmi web sitesini ziyaret edin.(",
                p8: ")Sorgulama yap. HKDFINANC'nin bütün işletme operasyonları ciddi kontrol altında yapılır ve tüm kurallara uyuyor.",
                p9: "Arkaplan",
                p10: "HKDFINANC, finansal ticaret ve fintech endüstrilerinde zengin deneyimler ve bilgiler olan üst bir takımdan oluşur. HKDFINANC filozofisi transaksyonları kolaylaştırmak ve daha arkadaşlık etmek.",
                p11: "HKDFINANC'ın filozofisi transaksyonları kolaylaştırmak ve daha arkadaşlık etmek. Bizim holistik stratejimiz ve iş modellerimiz endüstri geliştirme ve pazar talebinin büyük ve derinlikli bir anlama sağlayabilir, bize daha hedefli, sürekli yenileme teknolojisini sağlayarak, etkileşimliliğini iyileştirir, müşterileri sürekli daha uygun ve dost ticaret deneyimlerini sağlayarak",
                p12: "Neden seçiyorsun",
                p13: "Düşük sınıf ticaret şartları",
                p14: "Basit ve düşünceli ticaret platformu",
                p15: "Konkursiv transaksyon maliyetleri",
                p16: "Sanayi yetkilileri tarafından kontrol edilmiş",
                p17: "Yüksek seviye internet desteği",
                p18: "Negatif denge koruması",
                p19: "Ödüller ve Saygılar",
                p20: "HKDFINANC, ABŞ Pasifik bölgesindeki en iyi Mobil Satış Platformu ve Global Fast Growing Financial Technology Broker'ı dahil, Dünya Finans Ödülleri'nin En iyi Multi Asset Broker'ı, Global BrandsMagazine'in Asia Pacific bölgesindeki en iyi Mobil Satış Platformu ve Global Fast Growing Financial Technology Broker'ı kabul etti. Bunların hepsi ekibinin s ürekli çabalarını ve bağışlanmasını onayla",
                p21: "En iyi Çoklu Mali Broker",
                p22: "Operasyonal filozofimiz",
                p23: "Sorumluluk",
                p24: "Tüm transaksyonlar riskleri içeriyor ve HKDFINANC müşterilere sorumlu ticaret çevresini sağlamak için daha fazla ölçüm alıyor. HKDFINANC, sanayi yetkilisi, birçok kurumlardan ciddi gözlemi ve yüksek seviye güvenlik ve stabilit işleme standartlarına uyuyor. Platformumuz çok sorumlu ticaret araçlarını sağlamaya devam ediyor. Risk yönetimi de dahil.",
                p25: "Basit ama güzel.",
                p26: "Basit güzellik ve detaylar mükemmel bir şekilde ulaşır. HKDFINANC basit detayları, kullanımı kolayca ve kullanıcı deneyimi için çalışıyor. Platformumuz ve hizmetlerimizi iyileştirmeye devam ediyoruz, herkes, yeni ya da deneyimli yatırımcılar, HKDFINANC platformu üzerinden ticaret fırsatlarını kolayca yakalayabilir.",
                p27: "Transparent",
                p28: "Müşterilerimize adil ve etkili ticaret çevresinden tamamen fayda vermek için HKDFINANC, bütün kapsamlı ve transparent platformlu ürünlerin bilgisini, fiyat çevresini ve verileri sağlayan ve sağlam ve transparent kendi düzenleme sistemini takip ediyor. Tüm ücretler temiz ve transparent, tüm hizmet sürecinin tamamen dürüstlüğünü ve transparentliğini sağlayan.",
                p29: "Yenileştir",
                p30: "HKDFINANC teknolojik ilerleme, finansal yenileme ve daha geniş hizmetler büyütmek için sürekli yaratmak ve paylaşmak için sürücü güçleri olduğuna inanıyor. Bu nedenle açık düşünceleri teşvik ediyoruz, yenilikleri sürekli terfi ediyoruz ve finansal teknolojide yenilikçiler ve liderler olmayı amaçlıyoruz.",
                p31: "Görüntüler ve Görüntüler",
                p32: "Uzay, zamanı veya pazarlıkla sınırlı olmayan adil, etkili ve transparent ticaret çevresini sağlamak için, sanayinin finansal ve teknolojik yenilemesini yönetmek isteyen daha çok insan için bir pazar sağlamak.",
            },
        },
        language: {
            tit: "Dili seç",
        },
        market: {
            tit: "Pazar ayarlaması",
            tit1: "Ödül gösterisi",
            tit2: "Yukarı ve aşağı rengi",
            buy_price: "Satın hızı",
            buy_price_tip: "Satın fiyatına bir K-çizgi çiz",
            sell_price: "Satış hızı",
            sell_price_tip: "K-satış fiyatına çiz",
            color1: "Yeşil yükselme ve kırmızı düşme",
            color2: "Kırmızı yükselme ve yeşil düşme",
        },
        notice: {
            tit: "Bildirimler ayarları",
            tit1: "Bildirim kanallarını aç",
            p1: "Lütfen aşağıdaki bildirim kanallarını açın/doğrulayın, önemli mesajlar kaçırılmaz!",
            message: "Metin mesajı",
            push: "Bastır",
            verify: "Teste ve doğrula",
            verified: "Verilen",
            email: "E-posta",
            type1: "MARKETING",
            tit2: "İşaret Uyarısı Bildirimi",
            desc2: "Platformun terfi bilgileri, operasyon etkinlikleri ve diğer mesajlar",
            tit3: "İşlemler",
            desc3: "Zengin ve profesyonel bilgi materyalleri",
            tit4: "Bilgi hatırlatıcısı",
            desc4: "Son zamanlarda mali veri ve haber bilgileri",
            tit5: "Akıllı fluktasyon hatırlatıcısı",
            desc5: "Önemli çeşitliklerin fiyat değişiklikleri de dahil eder, size hızlı fırsatları almaya yardım eder.",
            type2: "Rapor Sınıfı",
            tit6: "Hesap Raporu",
            desc6: "Hesap raporunuz aylık olarak gönderilecek.",
            cycle: "Döngü",
            month: "Aylık",
        },
        protocol: {
            tit: "Anlaşma ve Anlaşma",
            label1: "Gizlilik politikası",
            label2: "Produkt Açıklama Paylamı",
            label3: "Müşteriler Anlaşması",
            label4: "Risk Açıklama Görüntüsü",
        },
        setting: {
            tit: "Ayarlar",
            label1: "Güvenlik",
            label2: "Pazar ayarlaması",
            label3: "Aktarma Ayarları",
            label4: "Dil",
            label5: "Tema modu",
            label6: "Bildirimler ayarları",
            label7: "Transaksyon Kılavuzu",
            label8: "Anlaşma ve Anlaşma",
            label9: "Temiz cache",
            label10: "Sürüm",
            logout: "Girişten çık",
        },
        theme: {
            tit: "Tema modu",
            theme1: "Işık",
            theme2: "Karanlık",
        },
        trade: {
            tit: "Aktarma Ayarları",
            onclick_close: "Bir tıklama kapatma pozisyonu",
        }
    },
    trade: {
        fliter_type1: "Seçeneksel",
        fliter_type2: "Pazar",
        place_search: "Çeşitli isim/kodu arama girin",
        fliter_type3: "Popüler",
        fliter_type4: "Dışişleri ",
        fliter_type5: "Üyelik",
        fliter_type6: "Paylaş ",
        fliter_type7: "Indeks",
        fliter_type8: "Şifreleme",
        tip1: "Yerleştirmek ve ticaret başlatmak için tamam kimlik doğrulaması",
        sell: "Satın",
        buy: "Alın.",
        add_optional: "Kendi Seçimi Ekle",
        optional_recommend: "Kendi seçim tavsiyesi",
        change_batch: "Toplu değiştir",
        edit_tit: "Seçimi Düzenle",
        breed: "Yemek",
        topping: "Toplanma",
        sort: "Sort",
        all: "Hepsini Seç",
    },
    table: {
        label_breed: "Yemek",
        label_direction: "Doğru",
        label_price: "Ödül",
        label_operate: "İşle",
        empty_tip1: "Şu anda hatırlatma yok",
        empty_tip2: "Depozit kayıtları yok",
        empty_tip3: "Para ödeme kayıtları yok",
        label_amount: "Kıymet",
        label_type: "Türler ",
        label_time: "Zaman",
        label_name: "İsim",
        label_email: "E-posta",
        label_address: "Ülke",
        label_account: "Trading Account Number",
        label_currency: "Temel Valüt",
        label_company: "Güncel Hizmet Entitesi",
    },
    form: {
        to: "- Evet.",
        start_time: "Başlangıç vakti",
        end_time: "Son zamanı",
        week: "Geçen hafta",
        month: "Geçen ay",
        threeMonth: "Geçen üç ay",
        sixMonth: "Geçen altı ay",
        label_feedback: "Sorunlar ve öneriler",
        place_feedback: "Lütfen sorunuzu ya da teklifinizi girin",
        label_img: "Resim",
        label_img_tip: "Seçeneksel, problemin ekran fotoğraflarını sunun",
        feedback_tip: "Acil sorularınız varsa, lütfen temas edin.",
        online: "Çevrimiçi Hizmeti",
        label_cardNumber: "Kimlik Numarası",
        label_realName: "Tam isim",
        place_realName: "Lütfen adınızı girin.",
        message_realName: "Lütfen tam adınızı girin.",
        label_firstName: "Surname",
        place_firstName: "Lütfen soyadınızı girin",
        message_firstName: "Lütfen soyadı girin",
        label_lastName: "İsim",
        place_lastName: "Lütfen orta isminizi girin",
        message_lastName: "Lütfen bir isim girin",
        label_birthday: "Doğum günü",
        place_birthday: "Lütfen doğum gününüzü girin",
        message_birthday: "Lütfen doğum tarihini girin",
        label_idType: "Verifikasyon Türü",
        tip_cardMain: "Kimlik kartınızın FRONT fotoğrafını çekin ya da yükleyin",
        tip_cardBack: "Kimlik kartınızın BEHIND fotoğrafını çekin ya da yükleyin",
        tip_cardMain1: "Sürücünüz lisansınızın FRONT fotoğrafını çekin ya da yükleyin",
        tip_cardBack1: "Sürücüs ünün ehliyetinin BEHIND fotoğrafını çek ya da yükle",
        tip_cardMain2: "Pasaportunuzdan bir fotoğraf çekin ya da yükleyin",
        tip_cardBack2: "Pasaportunuzdan bir fotoğraf çekin ya da yükleyin",
        label_password: "Parola",
        place_password: "Lütfen parolanı girin",
        message_password: "Lütfen parolanı girin",
        label_crmpsd: "Parola doğrula",
        place_crmpsd: "Lütfen parolanı tekrar girin",
        message_crmpsd: "Lütfen parolanı tekrar girin",
        label_email: "E-posta",
		place_email: "E-posta adresi",
		message_email: "Lütfen e-posta adresinizi girin",
        label_captcha: "Kontrol kodu",
		place_captcha: "Lütfen doğrulama kodunu girin",
		message_captcha: "Lütfen doğrulama kodunu girin",
		get_captcha: "Kontrol Kodu",
        label_phone: "Telefon",
		place_phone: "Lütfen bir telefon numarasını girin",
		message_phone: "Lütfen bir telefon numarasını girin",
        labelAmount: "Kıymet",
        placeAmount: "Lütfen, miktarı girin",
        messageAmount: "Lütfen, miktarı girin",
        labelWalletName: "Cüzdanın adı",
		messageWalletName: "Lütfen cüzdanın adını girin",
		placeWalletName: "Lütfen cüzdanın adını girin",
		labelWalletAddress: "Cüzdanın adresi",
		messageWalletAddress: "Lütfen cüzdanın adresini girin",
		placeWalletAddress: "Lütfen cüzdanın adresini girin",
        labelWithdrawAccount: "Kaçırma hesabı",
        placeWithdrawAccount: "Kaçırma hesabı",
        messageWithdrawAccount: "Kaçırma hesabı",
		placeTimeLimit: "Lütfen zaman sınırını girin",
		messageTimeLimit: "Lütfen zaman sınırını girin",
    },
    city: {
        albania: "Arnavutluk",
        algeria: "Aljerya",
        argentina: "Argentina",
        armenia: "Ermenistan",
        australia: "Avustralya",
        pakistan: "Pakistan",
        austria: "Avusturya",
        bahrain: "Bahrain",
        belgium: "Belçika",
        bosnia_and_Herzegovina: "Bosna ve Hercegovina",
        brazil: "Brazilya",
        brunei: "Brunei",
        bulgaria: "Bulgaristan",
        cambodia: "Kambodza",
        canada: "Kanada",
        cameroon: "KamerunName",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Hırvatistan",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "DanmarkName",
        dominican_Republic: "Dominikan Cumhuriyeti",
        egypt: "Mısır",
        estonia: "İstonya",
        ethiopia: "Etiopya",
        finland: "Finland",
        france: "Fransa",
        georgia: "Georgia",
        germany: "Almanya",
        ghana: "Ghana",
        greece: "Yunanistan",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, Çin",
        hungary: "Hungary",
        iceland: "İslandiya",
        ireland: "İrlanda",
        italy: "İtalya",
        india: "Hindistan",
        indonesia: "Indonezi",
        israel: "İsrail",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japonya",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgistan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Litvanya",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Makedonya",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Meksika",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Karadağ",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: "Hollanda",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nijerya",
        norway: "Norveç",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Filipinler",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romanya",
        russia: "Rusya",
        republic_of_Trinidad_and_Tobago: "Trinidad ve Tobago Cumhuriyeti",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arapça",
        serbia: "Sırbistan",
        singapore: "Singapur",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "Güney Afrika",
        spain: "İspanya",
        sri_Lanka: "Sri Lanka",
        sweden: "İsveç",
        switzerland: "İsviçre",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Tayland",
        turkey: "Türkiye",
        turkmenistan: "Türkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Birleşik Arap Emirtleri",
        united_Kingdom: "Birleşik Krallık",
        united_States: " Birleşik Devletler",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladeş",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "İngiliz bakire adaları",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Adaları",
        central_African_Republic: "Merkezi Afrika Cumhuriyeti",
        chad: "Chad",
        comoros: "Komoros",
        the_Republic_of_the_Congo: " Kongo Cumhuriyeti",
        democratic_Republic_of_the_Congo: "Kongo Demokratik Cumhuriyeti",
        djibouti: "Djibouti",
        ecuador: "Ekvador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Ekvatorial Gine",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaika",
        jordan: "Jordan",
        lebanon: "Liban",
        lesotho: "Lesotho",
        liberia: "Liberya",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " Kuzey Kore",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somaliya",
        sudan: "Sudan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Birleşik Devletler Virgin Adaları",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
